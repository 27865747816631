<template>
    <svg viewBox="0 0 351 345" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
            <linearGradient x1="0%" y1="100%" x2="100%" y2="0%" :id="_uid">
                <stop stop-color="var(--color-image-main)" stop-opacity="0.401606206" offset="0%"></stop>
                <stop stop-color="var(--color-image-main)" stop-opacity="0.100442526" offset="100%"></stop>
            </linearGradient>
        </defs>
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(-3.000000, 0.000000)">
                <g transform="translate(8.909396, 0.473154)" :fill="`url(#${ _uid })`" fill-rule="nonzero">
                    <circle cx="172.248322" cy="172.248322" r="172.248322"></circle>
                </g>
                <g transform="translate(0.000000, 25.702511)" fill="var(--color-image-secondary-2)" fill-rule="nonzero">
                    <path d="M62.9970578,239.775439 C59.2604549,241.592301 56.2158154,243.828438 51.9256416,243.828438 C43.4836867,243.968197 41.4077962,237.67906 40.57744,230.691129 C39.7470837,223.56344 38.0863713,217.55382 31.720307,213.500821 C21.894425,207.071925 5.7024788,211.963476 5.01051528,224.681509 C4.73372987,228.874267 9.99265259,229.293543 12.3453285,227.197164 C15.6667534,224.262233 18.4346075,219.789958 23.5551375,220.768268 C30.4747726,222.165854 30.1979872,229.433302 31.1667361,234.883888 C32.1354851,240.753749 34.9033391,245.785059 39.7470837,249.279024 C48.1890386,255.148886 62.8586651,253.7513 65.9033046,242.430852 C66.4568754,240.893508 64.5193776,239.076646 62.9970578,239.775439 L62.9970578,239.775439 Z" transform="translate(35.500000, 231.676823) rotate(-18.000000) translate(-35.500000, -231.676823) "></path>
                    <path d="M302.800671,0.192119553 C292.34698,5.06258935 282.012081,10.289435 271.677181,15.5162806 C269.18255,16.822992 268.826174,19.4364149 270.726846,21.4558779 C278.32953,29.2961464 285.694631,37.1364149 293.891275,44.3827236 C295.43557,45.8082269 297.930201,44.9766833 298.761745,43.1948041 C303.751007,32.1471531 311.472483,17.1793679 309.334228,4.70621351 C308.85906,1.49883096 303.988591,0.0733276064 302.681879,3.637086 C300.662416,9.22030747 300.662416,15.3974887 299.355705,21.0995021 C297.811409,27.7518511 295.910738,34.4042001 292.94094,40.5813813 C294.722819,40.3437974 296.62349,40.2250055 298.405369,39.9874216 C291.040268,31.5531934 282.962416,23.9505088 275.003356,16.1102404 C275.003356,17.8921196 274.884564,19.7927907 274.884564,21.5746699 C284.981879,15.8726565 294.960403,10.170643 304.938926,4.11225378 C306.958389,2.80554237 305.057718,-0.877007964 302.800671,0.192119553 L302.800671,0.192119553 Z"></path>
                </g>
                <g transform="translate(278.796471, 211.933397)" fill="var(--color-image-secondary-1)" fill-rule="nonzero">
                    <path d="M0.00822655431,19.7088846 C0.839770178,25.292106 6.66057555,37.1713007 12.8377568,38.8343879 C16.1639313,39.6659315 18.5397702,37.5276765 21.0344011,35.7457973 C25.667287,32.5384148 30.1813809,29.3310322 34.8142668,26.0048577 C36.4773541,24.8169383 37.4276896,22.5598913 36.2397702,20.6592201 C32.200844,14.4820389 28.1619178,8.18606578 24.1229917,2.00888457 C23.0538641,0.345797319 20.5592333,-0.723330197 18.7773541,0.583381212 C13.7880923,4.1471396 8.3236628,7.4733141 3.69077689,11.6310322 C0.602186286,14.4820389 4.52232051,19.2337168 8.0860789,17.3330456 C13.1941326,14.6008309 17.9458104,10.6806966 22.6974883,7.35452215 C20.9156091,6.87935437 19.1337299,6.40418658 17.3518507,5.9290188 C21.3907769,12.1062 25.4297031,18.4021732 29.4686292,24.5793544 C29.943797,22.7974752 30.4189648,21.015596 30.8941326,19.2337168 C28.1619178,21.1343879 25.4297031,23.153851 22.6974883,25.0545222 C21.153193,26.1236497 15.9263474,30.9941195 14.2632601,30.8753275 C12.7189648,30.7565356 9.51158226,25.1733141 8.56124669,24.1041866 C6.66057555,21.8471396 4.75990441,19.5900926 2.26527353,17.9270054 C1.07735407,17.2142537 -0.110565392,18.5209651 0.00822655431,19.7088846 L0.00822655431,19.7088846 Z"></path>
                </g>
                <path d="M206.61092,53.4236617 C211.065417,52.1022969 215.924869,51.4924363 220.480604,52.5088707 C225.036339,53.5253051 229.389598,56.1680345 231.718085,60.2337721 C234.350288,64.9093703 234.14781,70.6014029 233.742856,75.9885052 C232.932948,89.4054392 232.123039,102.822373 231.313131,116.239307 C231.110653,120.000114 230.908176,123.862565 232.325516,127.318442 C233.742856,130.774319 237.387445,133.620335 241.032033,132.807188 C242.854327,132.400614 244.474144,131.180893 246.1952,130.469389 C247.916255,129.757885 250.244742,129.757885 251.358367,131.282536 C251.965798,132.197327 252.067037,133.417048 251.965798,134.535126 C251.459605,139.515655 248.32121,144.08961 244.06919,146.529052 C246.600154,145.309331 249.839788,147.850417 249.839788,150.696433 C249.839788,153.542449 247.61254,155.981892 244.980337,157.09997 C242.348134,158.116404 239.412216,158.116404 236.577536,158.116404 C238.906023,158.116404 240.728317,160.65749 240.52584,162.995289 C240.323363,165.333088 238.501069,167.264314 236.27382,168.179105 C234.14781,169.093896 231.718085,169.093896 229.389598,168.890609 C224.428908,168.382392 219.468218,166.85774 215.621153,163.706793 C208.838169,158.319691 206.104728,149.070138 206.003489,140.328802 C205.902251,131.587466 208.02826,123.049418 209.243123,114.409725 C212.077803,94.3859677 209.749316,74.0572798 207.420829,53.9318788 C208.02826,53.728592 208.534453,53.6269485 209.141885,53.4236617" fill="#B63B03" fill-rule="nonzero"></path>
                <path d="M167,149.258537 L69.1408897,185.98746 L78.4995572,146.511499 L53.0683955,140 C53.0683955,140 25.7044654,217.222305 44.7269744,219.358891 C77.9909339,223.021609 128.344634,209.795127 128.344634,209.795127 L167,149.258537 Z" fill="#FFE6D5" fill-rule="nonzero"></path>
                <path d="M133,238.291763 L133.204329,316.054124 C158.745455,347.526932 247.935065,306.857524 247.935065,306.857524 L248.95671,275.078163 L251,199.359491 C248.95671,169.21508 220.963636,154.398336 220.963636,154.398336 C209.419048,148.676007 191.744589,145.30392 175.806926,146.121396 C159.154113,146.938871 155.067532,154.909258 148.222511,170.032555 C142.705628,182.294689 133,193.739346 133,238.291763 Z" fill="#FFFFFF" fill-rule="nonzero"></path>
                <path d="M216.213796,73.2722347 L214.997291,88.7203363 C214.997291,88.7203363 228.074717,90.4480845 224.323827,102.44069 C220.572937,114.433295 211.651903,109.148418 211.651903,109.148418 C211.651903,109.148418 209.421644,139.841357 177.995272,130.084661 C153.056926,122.36061 154.577556,89.9399233 157.720194,62.1943197 C159.038074,51.624566 169.074238,44.3070441 179.617279,46.3396891 L198.473102,49.99845 C209.421644,52.1327272 217.024799,62.0926875 216.213796,73.2722347 Z" fill="#FFE6D5" fill-rule="nonzero"></path>
                <path d="M215,102 L206.641398,147.201714 C204.727983,157.359403 194.758085,163.860323 184.788186,161.524055 L184.788186,161.524055 C175.52323,159.289364 169.581574,150.147444 171.293576,140.700793 L175.825348,114.798688 L215,102 Z" fill="#FFE6D5" fill-rule="nonzero"></path>
                <path d="M181,101.868962 C181,101.868962 181,101.971784 180.894226,102.177427 C180.788452,102.38307 180.576904,102.691535 180.153808,102.897178 C179.730713,103.102822 179.096069,103 178.567199,102.588713 C178.03833,102.280249 177.615234,101.560497 177.403686,100.840746 C176.874816,99.4012429 176.98059,97.5504535 177.086364,95.6996641 C177.192138,93.8488748 177.297912,91.7924421 177.403686,89.6331878 C177.721008,85.3146793 178.03833,81.4074573 178.249877,78.5284516 C178.567199,75.6494459 178.778747,73.9014782 178.884521,74.0042998 C178.990295,74.0042998 179.096069,75.7522675 178.990295,78.6312732 C178.884521,81.5102789 178.672973,85.4175009 178.461425,89.7360095 C178.355651,91.8952637 178.144104,93.9516964 178.03833,95.8024858 C177.932556,97.6532751 177.721008,99.2984212 178.03833,100.635102 C178.249877,101.971784 179.307617,102.588713 180.048035,102.485892 C180.682678,102.38307 180.894226,101.868962 181,101.868962 Z" fill="#333333" fill-rule="nonzero"></path>
                <path d="M170.005432,127.007252 C170.106748,126.902224 171.52517,127.952507 174.159382,129.212847 C176.692278,130.473187 180.54228,131.733527 184.898862,131.838555 C189.255443,131.943584 193.105445,130.788272 195.739657,129.737989 C198.373869,128.582677 199.893607,127.532394 199.994923,127.742451 C200.096239,127.847479 198.677817,129.107819 196.043605,130.473187 C193.409393,131.838555 189.458075,133.098895 184.898862,132.993867 C180.339649,132.888839 176.388331,131.418442 173.855434,129.948046 C171.221222,128.477649 169.904116,127.112281 170.005432,127.007252 Z" fill="#333333" fill-rule="nonzero"></path>
                <path d="M176.014844,108.971465 C176.116978,108.872178 176.831914,109.567184 178.159651,110.162904 C179.487389,110.758624 181.530062,111.255057 183.67487,110.659337 C185.819677,109.964331 187.249548,108.375745 187.86235,107.184305 C188.577286,105.89358 188.679419,105 188.883687,105 C188.98582,105 189.190088,105.992866 188.577286,107.482165 C187.964484,108.971465 186.432479,110.85791 183.981271,111.652203 C181.530062,112.446496 179.078854,111.75149 177.751117,110.85791 C176.321245,109.865044 175.912711,108.971465 176.014844,108.971465 Z" fill="#333333" fill-rule="nonzero"></path>
                <path d="M216,103.835549 C216,103.634745 216.840112,103.634745 217.785238,103.132736 C218.310307,102.831531 218.730363,102.429923 218.940391,101.827513 C219.150419,101.225102 218.940391,100.522289 218.310307,100.321485 C217.890251,100.120682 217.365182,100.321485 217.260168,100.522289 L216.315042,100.02028 C217.470196,97.6106364 218.520335,95.9038056 218.730363,96.0042074 C218.940391,96.1046092 218.310307,98.0122437 217.260168,100.421887 L216.315042,99.9198781 C216.52507,99.5182709 216.945126,99.3174673 217.365182,99.2170654 C217.785238,99.1166636 218.205293,99.1166636 218.625349,99.3174673 C219.255433,99.5182709 219.675489,100.02028 219.885517,100.522289 C220.095545,101.024298 219.990531,101.626709 219.780503,102.028316 C219.465461,102.931933 218.625349,103.433942 218.100279,103.634745 C216.840112,104.237156 216,103.935951 216,103.835549 Z" fill="#333333" fill-rule="nonzero"></path>
                <path d="M192,73.5400233 C192,73.4555638 192.289157,73.2021855 192.86747,73.1177261 C193.445783,72.9488072 194.313253,72.9488072 195.277108,73.2021855 C196.240964,73.4555638 197.012048,73.7934016 197.39759,74.2156988 C197.879518,74.5535366 198.072289,74.8913744 197.975904,74.9758338 C197.783133,75.1447527 196.626506,74.3846177 195.084337,74.0467799 C193.542169,73.6244827 192.096386,73.7934016 192,73.5400233 Z" fill="#333333" fill-rule="nonzero"></path>
                <path d="M165.004363,69.2074245 C164.906095,68.9721274 166.47838,67.7956422 168.640272,68.0309392 C170.802164,68.2662363 172.177914,69.6780186 171.981378,69.9133156 C171.784843,70.2662612 170.409093,69.4427215 168.542005,69.2074245 C166.576648,68.9721274 165.102631,69.56037 165.004363,69.2074245 Z" fill="#333333" fill-rule="nonzero"></path>
                <path d="M215.066322,89.1831657 C206.246798,80.606406 203.580431,66.2097022 201.221721,54.1613969 C193.017512,60.0834453 181.736726,61.6150095 172.301886,57.9392553 C169.327861,61.4108009 165.841072,64.4739294 161.944073,66.9244322 C160.815995,67.6391622 159.585364,68.3538921 158.25218,67.945475 C156.713891,67.5370579 156.098575,65.8012851 155.790917,64.2697209 C154.662839,59.4708196 154.662839,54.2635012 156.30368,49.6688085 C157.944522,45.0741158 161.533863,40.8878402 166.14873,39.1520674 C171.071255,37.212086 176.711648,38.0289203 181.839279,39.356276 C189.633276,41.3983616 197.017064,44.4614901 203.990641,48.4435571 C210.759113,52.4256241 217.322479,57.6329425 220.193952,64.8823465 C223.065425,72.1317506 223.065425,86.3242458 216.091848,90" fill="#B63B03" fill-rule="nonzero"></path>
                <path d="M163.227747,149.318025 C171.811909,145.962754 106,170.263051 106,170.263051 L112.642506,215 L137.679646,206.866009 L163.227747,149.318025 Z" fill="#FFFFFF" fill-rule="nonzero"></path>
                <path d="M79.9705325,132.555798 C79.9705325,132.555798 79.9705325,132.350246 80.0715457,132.144693 L80.0715457,132.144693 L80.0715457,132.144693 C81.1826912,128.547524 89.1627364,102.442354 86.4353792,103.059011 C83.5059955,103.675669 76.6370959,123.100382 76.1320297,122.997606 C75.7279768,122.997606 82.0918103,101.311815 79.6674928,101.003486 C77.2431753,100.695157 71.3844079,120.942081 71.3844079,120.942081 C71.3844079,120.942081 75.4249371,101.72292 72.8996064,101.311815 C70.3742756,100.90071 66.5357729,118.578227 66.5357729,118.578227 C66.5357729,118.578227 69.5661698,103.675669 67.2428655,103.36734 C65.8286802,103.161788 53,139.955689 53,139.955689 C53.3030397,139.955689 76.4350694,149 76.4350694,149 C76.4350694,149 78.455334,146.122265 80.8796515,142.422319 L79.9705325,132.453022 C80.0715457,132.453022 79.9705325,132.453022 79.9705325,132.555798 Z" fill="#FFE6D5" fill-rule="nonzero"></path>
                <path d="M208.723388,235 C208.623058,235 208.422398,233.776917 208.121407,231.534597 C207.820416,229.292278 207.419095,225.928799 206.917444,221.953778 C205.914142,213.799889 204.710179,202.588292 203.305556,190.153611 C203.004565,186.993979 202.803905,183.936271 203.305556,180.878563 C203.706877,177.922778 204.91084,175.068917 206.616454,172.928521 C208.322067,170.686201 210.429002,169.055424 212.535937,167.934264 C214.642871,166.711181 216.850136,165.997715 218.856741,165.590021 C222.86995,164.774632 226.180847,164.978479 228.488442,165.182326 C229.592075,165.28425 230.495047,165.488097 231.097028,165.590021 C231.699009,165.691945 232,165.793868 232,165.793868 C232,165.895792 230.796037,165.691945 228.488442,165.590021 C226.281177,165.488097 222.97028,165.386174 219.057401,166.303486 C217.151127,166.813104 215.044192,167.52657 213.037588,168.647729 C211.030983,169.870813 209.024379,171.399667 207.519426,173.540063 C205.914142,175.578535 204.810509,178.228549 204.409189,181.08241 C204.007868,183.936271 204.108198,186.993979 204.409189,190.051688 C205.713481,202.486368 206.917444,213.697965 207.720086,221.851854 C208.121407,225.928799 208.422398,229.190354 208.623058,231.534597 C208.723388,233.674993 208.723388,235 208.723388,235 Z" fill="#333333" fill-rule="nonzero"></path>
                <path d="M206,232.056959 C206,231.7359 217.901966,232.806096 232.550539,234.411392 C247.199113,236.016687 259.101079,237.621982 258.999353,237.943041 C258.999353,238.2641 247.097387,237.193904 232.448813,235.588608 C217.80024,233.876293 206,232.378018 206,232.056959 Z" fill="#241352" fill-rule="nonzero"></path>
                <g transform="translate(39.000000, 76.000000)" fill="var(--color-image-main)" fill-rule="nonzero">
                    <polygon points="6.69230769 0 0 53.8817006 58 55 58 3.76155268"></polygon>
                </g>
                <path d="M74,104.5 C77.315,104.5 80,101.926875 80,98.75 C80,95.573125 77.315,93 74,93 C70.685,93 68,95.573125 68,98.75 C68,101.926875 70.685,104.5 74,104.5 Z M60.5,101.625 L60.5,98.75 C60.5,97.959375 59.825,97.3125 59,97.3125 C58.175,97.3125 57.5,97.959375 57.5,98.75 L57.5,101.625 L54.5,101.625 C53.675,101.625 53,102.271875 53,103.0625 C53,103.853125 53.675,104.5 54.5,104.5 L57.5,104.5 L57.5,107.375 C57.5,108.165625 58.175,108.8125 59,108.8125 C59.825,108.8125 60.5,108.165625 60.5,107.375 L60.5,104.5 L63.5,104.5 C64.325,104.5 65,103.853125 65,103.0625 C65,102.271875 64.325,101.625 63.5,101.625 L60.5,101.625 Z M62,113.125 C62,109.30125 69.995,107.375 74,107.375 C78.005,107.375 86,109.30125 86,113.125 L86,114.5625 C86,115.353125 85.325,116 84.5,116 L63.5,116 C62.675,116 62,115.353125 62,114.5625 L62,113.125 Z" fill="#FFFFFF"></path>
                <path d="M166.13495,83.9672265 C165.879437,83.860224 166.049779,83.0042044 166.13495,81.9341798 C166.220121,80.9711577 166.220121,80.115138 166.475634,80.0081355 C166.731148,79.9011331 167.071832,80.8641552 166.986661,82.0411822 C166.90149,83.3252117 166.390463,84.1812314 166.13495,83.9672265 Z" fill="#333333" fill-rule="nonzero"></path>
                <path d="M192.179266,86.9936988 C191.910367,86.8938249 192,85.7952111 192.089633,84.4968494 C192.179266,83.1984877 192.268899,82.099874 192.448165,82 C192.717063,82 193.075595,83.0986137 192.985962,84.4968494 C192.985962,85.9949591 192.448165,87.0935728 192.179266,86.9936988 Z" fill="#333333" fill-rule="nonzero"></path>
                <path d="M187.903603,108.654483 C188.311126,109.969732 187.394199,111.378927 185.764106,111.848658 C184.134013,112.31839 182.503921,111.660766 182.096397,110.345517 C181.688874,109.030268 182.605801,107.621073 184.235894,107.151342 C185.764106,106.68161 187.496079,107.339234 187.903603,108.654483 Z" fill="var(--color-image-main)" fill-rule="nonzero" opacity="0.18"></path>
                <path d="M204,91.9480519 C204,94.2337662 200.943662,96 197,96 C193.15493,96 190,94.2337662 190,92.0519481 C190,89.8701299 193.056338,88 197,88 C200.84507,88 204,89.7662338 204,91.9480519 Z" fill="var(--color-image-main)" fill-rule="nonzero" opacity="0.13"></path>
                <path d="M174,93.9502582 C174,96.234728 170.943662,98 167,98 C163.15493,98 160,96.234728 160,94.0540978 C160,91.8734676 163.056338,90.004356 167,90.004356 C170.84507,89.9005165 174,91.6657885 174,93.9502582 Z" fill="var(--color-image-main)" fill-rule="nonzero" opacity="0.13"></path>
                <path d="M217.314685,67.8154458 C217.314685,67.8154458 216.730653,68.2306927 216.827992,67.8154458 C217.022669,67.4001989 217.284837,65.9468349 217.09016,65.1163411 C216.895482,64.2858473 216.692368,63.6813012 216.108336,62.7469957 C215.621643,61.7088785 215.084032,60.66644 214.5,59.4206994 C213.331936,57.1368415 212.240382,54.5275456 211.559011,52.9703698 C210.87764,51.413194 210.970107,51.1038117 211.067446,51 C211.164785,51 211.474363,51.413194 212.253072,52.9703698 C213.031782,54.5275456 214.102508,56.60378 215.270572,58.8876379 C215.854604,60.0295668 216.341298,61.1714957 216.827992,62.2096129 C217.314685,63.2477301 217.70404,64.1820356 217.898717,65.1163411 C218.093395,65.9468349 217.996056,66.7773286 217.70404,67.1925755 C217.509362,67.7116341 217.314685,67.9192576 217.314685,67.8154458 Z" fill="#333333" fill-rule="nonzero"></path>
                <g transform="translate(135.000000, 152.000000)" fill="var(--color-image-main)" fill-rule="nonzero">
                    <polygon points="5.8369153 64.1175799 0 141.230594 81 152 75.4702908 63"></polygon>
                    <polyline points="12 12.2135922 12 74 21 70.407767 21 0 12 2.87378641"></polyline>
                    <path d="M86.5654995,3 C86.5654995,3 50.207737,7.67918623 61.0030391,66.9827856 L70.0670191,68 C70.0670191,68 59.7809294,12.256651 94,8.28951487 L86.5654995,3 Z"></path>
                </g>
                <path d="M156,152 L156,215.874 L195.3725,215.240709 C186.839192,160.050386 220.858481,155.096931 221.554676,155.001454 L221.5655,155 L229,160.289515 C201.014459,163.533976 202.79635,201.409139 204.374226,215.100559 L210.470291,215 L216,304 L135,293.230594 L140.836915,216.11758 L147,216.018 L147,154.873786 L156,152 Z" fill="#FFFFFF" fill-rule="nonzero" opacity="0.202805"></path>
                <path d="M210.478267,224 C210.478267,224 222.897305,297.164347 205.388497,295.432044 C147.874099,289.521832 113.569051,288.70663 113.569051,288.70663 L104,308.57717 C103.796693,311.939877 213.023151,343.22324 230.531959,332.931319 C242.950997,325.594504 262.699304,305.927765 253.741309,247.131347 L251.603606,227.464607 L210.478267,224 Z" fill="#FFE6D5" fill-rule="nonzero"></path>
                <path d="M242.486653,175.446159 C242.486653,175.446159 256.025546,188.346056 259,237 L206.690641,231.311856 C206.690641,231.311856 197.972414,148.325902 242.486653,175.446159 Z" fill="#FFFFFF" fill-rule="nonzero"></path>
                <path d="M120.165714,288.612461 C120.165714,288.612461 92.2157832,279.612167 93.224806,282.612265 C94.1329265,285.612363 98.9762357,285.715815 103.718643,290.474591 C103.718643,290.474591 72.5398395,290.784946 74.0533737,293.474689 C75.5669078,296.060981 95.8482652,296.785142 95.9491674,297.198949 C96.0500697,297.612755 73.7506668,298.233465 74.2551782,300.612854 C74.7596896,303.095693 95.5455583,302.474983 95.5455583,302.474983 C95.5455583,302.474983 76.2732237,304.544017 76.6768328,307.23376 C77.0804419,309.820051 94.8392424,307.957921 94.8392424,307.957921 C94.8392424,307.957921 80.0066079,309.716599 80.410217,312.095988 C80.6120216,313.544311 123.899098,313.854666 124,314.992634 C123.899098,315.509892 120.165714,288.612461 120.165714,288.612461" fill="#FFE6D5" fill-rule="nonzero"></path>
                <path d="M156.265658,217 C156.370564,217 156.790188,218.2 157,220.4 C157,221.5 156.895094,222.9 156.160752,224.3 C155.741128,225 155.216598,225.6 154.482256,226.1 C153.747913,226.6 152.803759,227 151.859605,227 C149.866391,227 148.2928,225.8 147.453552,224.6 C146.509398,223.3 146.299586,222 146.089774,220.9 C145.879962,218.7 146.089774,217.4 146.19468,217.4 C146.404492,217.4 146.404492,218.7 146.824116,220.8 C147.033928,221.8 147.453552,223 148.187894,224.1 C149.027142,225.1 150.286015,226.1 151.859605,226.1 C153.328289,226.1 154.587162,225.1 155.216598,224 C155.95094,222.9 156.055846,221.6 156.160752,220.6 C156.265658,218.4 156.055846,217 156.265658,217 Z" fill="#000000" fill-rule="nonzero"></path>
                <path d="M149.818542,217.467608 C149.930092,217.580157 149.59544,218.030353 149.706991,218.705647 C149.818542,219.043295 149.930092,219.380942 150.264745,219.60604 C150.487847,219.831138 150.93405,219.718589 151.268702,219.493491 C151.603355,219.268393 151.826456,218.818197 151.714906,218.593098 C151.714906,218.255451 151.380253,217.917804 151.157151,217.805255 C150.599397,217.467608 150.153194,217.580157 150.041643,217.467608 C150.041643,217.355059 150.041643,217.24251 150.376296,217.12996 C150.599397,217.017411 151.045601,216.904862 151.603355,217.12996 C152.049558,217.24251 152.718863,217.692706 152.941965,218.480549 C153.165066,219.268393 152.718863,220.168785 152.049558,220.618982 C151.380253,221.069178 150.376296,221.181727 149.706991,220.618982 C149.037686,220.056236 148.926135,219.380942 149.037686,218.930746 C149.037686,218.368 149.260787,218.030353 149.483889,217.805255 C149.59544,217.467608 149.706991,217.467608 149.818542,217.467608 Z" fill="#000000" fill-rule="nonzero"></path>
                <path d="M204.502183,215.007814 C204.605204,214.904501 205.22333,215.834323 204.914267,217.280712 C204.811246,218.003907 204.399162,218.830416 203.678015,219.55361 C202.956868,220.276805 201.926658,220.896686 200.690406,221 C199.454154,221 198.320923,220.483432 197.599776,219.863551 C196.878629,219.140356 196.363524,218.417161 196.157482,217.693967 C195.745398,216.247577 196.260503,215.317755 196.363524,215.317755 C196.569566,215.317755 196.363524,216.247577 196.878629,217.384026 C197.084671,218.003907 197.599776,218.623788 198.217902,219.140356 C198.836028,219.656924 199.660196,219.966865 200.587385,219.966865 C201.514574,219.966865 202.338742,219.450297 202.853847,218.933729 C203.471973,218.417161 203.884057,217.693967 204.090099,217.074085 C204.605204,215.834323 204.296141,215.007814 204.502183,215.007814 Z" fill="#000000" fill-rule="nonzero"></path>
                <path d="M198.114286,213.592593 C198,213.493827 198.571429,213 199.714286,213 C200.285714,213 200.971429,213.197531 201.542857,213.790123 C201.771429,214.08642 202,214.481481 202,214.876543 C202,215.271605 201.885714,215.666667 201.657143,216.061728 C201.428571,216.45679 201.085714,216.753086 200.514286,216.851852 C200.057143,217.049383 199.485714,217.049383 199.028571,216.851852 C198.571429,216.654321 198.342857,216.358025 198.114286,216.061728 C198,215.765432 198,215.469136 198,215.17284 C198.114286,214.679012 198.457143,214.283951 198.8,214.185185 C199.142857,214.08642 199.371429,214.185185 199.371429,214.185185 C199.371429,214.283951 199.257143,214.382716 199.142857,214.481481 C199.028571,214.679012 198.914286,214.876543 198.914286,215.17284 C198.914286,215.469136 199.142857,215.864198 199.485714,215.962963 C199.828571,216.061728 200.285714,215.864198 200.628571,215.469136 C200.971429,215.074074 200.971429,214.679012 200.628571,214.283951 C200.4,213.987654 199.942857,213.691358 199.485714,213.592593 C198.8,213.493827 198.228571,213.691358 198.114286,213.592593 Z" fill="#000000" fill-rule="nonzero"></path>
                <g opacity="0.95" transform="translate(151.000000, 242.000000)" fill="#333333" fill-rule="nonzero">
                    <path d="M45.6384625,6.58596526 C45.3339231,6.58596526 45.23241,4.76215949 45.0293838,2.6343861 C46.0445149,3.54628899 45.3339231,2.93835373 45.5369494,3.14099882 L45.5369494,3.14099882 L45.5369494,3.14099882 L45.5369494,3.14099882 L45.5369494,3.14099882 L45.5369494,3.14099882 L45.4354362,3.14099882 L45.3339231,3.14099882 L45.1308969,3.14099882 L44.7248444,3.14099882 C44.5218182,3.14099882 44.2172788,3.14099882 44.0142526,3.03967627 C43.506687,3.03967627 43.1006345,2.93835373 42.6945821,2.83703119 C41.9839902,2.73570865 41.4764246,2.53306356 41.4764246,2.43174102 C41.4764246,2.33041848 41.9839902,2.12777339 42.6945821,2.12777339 C43.1006345,2.12777339 43.506687,2.02645085 44.0142526,2.02645085 C44.3187919,2.02645085 44.5218182,2.02645085 44.7248444,2.02645085 L45.1308969,2.02645085 L45.3339231,2.02645085 L45.4354362,2.02645085 L45.5369494,2.02645085 L45.5369494,2.02645085 L45.5369494,2.02645085 L45.5369494,2.02645085 L45.5369494,2.02645085 L45.5369494,2.02645085 C45.7399756,2.22909593 45.0293838,1.51983814 46.0445149,2.53306356 C46.3490543,4.76215949 45.9430018,6.6872878 45.6384625,6.58596526 Z"></path>
                    <path d="M39.6491885,20.7711212 C39.4461623,20.5684761 40.4612935,19.1499605 41.5779378,17.3261548 C42.6945821,15.502349 43.6082001,13.9825109 43.8112264,14.1851559 C44.1157657,14.2864785 43.6082001,16.0089617 42.4915558,17.93409 C41.2733984,19.8592183 39.8522148,20.9737663 39.6491885,20.7711212 Z"></path>
                    <path d="M26.0464308,27.8636992 C26.0464308,27.5597315 27.6706406,27.1544414 29.700903,26.5465061 C31.7311653,25.9385709 33.3553752,25.3306356 33.4568883,25.5332807 C33.5584015,25.7359258 32.1372178,26.8504737 30.0054423,27.5597315 C27.8736669,28.1676668 26.0464308,28.1676668 26.0464308,27.8636992 Z"></path>
                    <path d="M11.0224893,24.7227004 C11.2255156,24.5200553 12.7482123,25.2293131 14.6769616,25.9385709 C16.6057108,26.7491512 18.3314338,27.2557639 18.2299207,27.5597315 C18.2299207,27.8636992 16.4026846,27.7623766 14.2709091,26.9517963 C12.2406467,26.1412159 10.8194631,25.026668 11.0224893,24.7227004 Z"></path>
                    <path d="M1.48025625,12.6653178 C1.78479561,12.5639953 2.49538743,14.1851559 3.51051861,16.0089617 C4.52564979,17.8327675 5.43926785,19.3526056 5.13472849,19.5552507 C4.93170226,19.7578958 3.61203173,18.4407027 2.49538743,16.5155744 C1.58176937,14.5904461 1.27723002,12.7666403 1.48025625,12.6653178 Z"></path>
                    <path d="M4.72867602,1.11454797 C4.72867602,1.41851559 2.9014399,1.62116068 0.668151312,1.51983814 C1.78479561,0.709257797 0.972690665,1.31719305 1.1757169,1.11454797 L1.1757169,1.11454797 L1.1757169,1.11454797 L1.1757169,1.11454797 L1.1757169,1.11454797 L1.1757169,1.11454797 L1.1757169,1.11454797 L1.1757169,1.21587051 L1.1757169,1.41851559 L1.07420378,1.82380576 C1.07420378,2.02645085 0.972690665,2.33041848 0.972690665,2.53306356 C0.871177547,2.93835373 0.871177547,3.3436439 0.76966443,3.74893407 C0.668151312,4.45819187 0.465125076,4.86348204 0.363611959,4.86348204 C0.262098841,4.86348204 0.0590726052,4.35686932 0.0590726052,3.64761153 C0.0590726052,3.24232136 0.0590726052,2.83703119 0.0590726052,2.33041848 C0.0590726052,2.12777339 0.0590726052,1.82380576 0.160585723,1.51983814 L0.262098841,1.11454797 L0.262098841,0.911902882 L0.262098841,0.81058034 L0.262098841,0.709257797 L0.262098841,0.709257797 L0.262098841,0.709257797 L0.262098841,0.709257797 L0.262098841,0.709257797 L0.262098841,0.709257797 C0.465125076,0.506612712 -0.245466748,1.11454797 0.76966443,0.303967627 C3.00295302,0.506612712 4.72867602,0.81058034 4.72867602,1.11454797 Z"></path>
                    <path d="M19.4480781,1.62116068 C19.4480781,1.92512831 17.8238682,2.12777339 15.7936059,2.02645085 C13.7633435,1.92512831 12.1391336,1.62116068 12.1391336,1.41851559 C12.1391336,1.11454797 13.7633435,0.911902882 15.7936059,1.01322542 C17.8238682,1.11454797 19.4480781,1.31719305 19.4480781,1.62116068 Z"></path>
                    <path d="M34.0659671,2.22909593 C34.0659671,2.53306356 32.4417572,2.73570865 30.4114948,2.6343861 C28.3812325,2.53306356 26.7570226,2.22909593 26.7570226,2.02645085 C26.7570226,1.72248322 28.3812325,1.51983814 30.4114948,1.62116068 C32.4417572,1.62116068 34.1674802,1.92512831 34.0659671,2.22909593 Z"></path>
                </g>
                <path d="M90.6175565,126 C87.4947457,126 85.9333404,130.274854 80,133.356725 L80.9368432,143 C85.8292467,136.140351 92.6994303,126 90.6175565,126 Z" fill="#FFE6D5" fill-rule="nonzero"></path>
            </g>
        </g>
    </svg>
</template>

<script>
export default {}
</script>

<style lang="scss">

</style>